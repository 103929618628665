import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import axios from 'axios'
import classNames from 'classnames'
import Collapse from '@mui/material/Collapse'
import map from 'lodash/map'
import some from 'lodash/some'
import includes from 'lodash/includes'
import split from 'lodash/split'
import moment from 'moment'
import SolidsView from './SolidsView'
import LiquidsView from './LiquidsView'
import FeedersView from './FeedersView'
import PlatesView from './PlatesView'
import ProductContainerModal from './ProductContainerModal'
import PlateContainerModal from './PlateContainerModal'
import FlashNotification from '../shared/flashNotification'
import { LocalizationContext } from '../shared/contexts'
import { t } from '../../i18n'
import Icon from '../shared/icon'
import { ReplenishmentOrder, ReplenishmentOrderAction } from './types'
import { ReplenishmentCartSidebar } from './ReplenishmentCartSidebar'
import { ReplenishmentOrderPlacedModal } from './ReplenishmentOrderPlacedModal'
import { ProductReplenishmentModal } from './ProductReplenishmentModal'
import { BowlReplenishmentModal } from './BowlReplenishmentModal'
import { ReplenishmentHistorySidebar } from './ReplenishmentHistorySidebar'
import consumer from '../../../src/cable'

const scope = 'stock_control'

const emptyCart = {
  productReplenishments: [],
  plateReplenishments: [],
  state: 'draft',
  deliveryDate: null,
  editableOrderId: null
} as ReplenishmentOrder

const Tab = ({ label, index, currentTabIndex, hasAlert, switchTab }) => {
  const classes = classNames(
    'tab', {
      'active': index === currentTabIndex,
      'alert-indicator': hasAlert
    }
  )

  return (
    <div className={classes} onClick={() => switchTab(index)}>
      {label}
    </div>
  )
}

const Tabs = ({ currentTabIndex, switchTab, alerts }) => {
  const tabs = [
    { name: 'solids', label: t('tabs.solid_products', { scope }) },
    { name: 'liquids', label: t('tabs.liquid_products', { scope }) },
    { name: 'feeders', label: t('tabs.feeders', { scope }) },
    { name: 'plates', label: t('tabs.bowls', { scope }) },
  ]

  return (
    <div className='tabs-wrapper flex-1'>
      {map(tabs, ({ name, label }, index) =>
        <Tab key={name}
          label={label}
          index={index}
          currentTabIndex={currentTabIndex}
          switchTab={switchTab}
          hasAlert={alerts[name]}
        />
      )}
    </div>
  )
}

const Search = ({ query, onChange }) => {
  return (
    <div className='search-wrapper form-group'>
      <input
        className='search-input form-control'
        value={query}
        onChange={onChange}
        placeholder='search ...'
      />
    </div>
  )
}

const CategoryWithChildren = ({
  id, name, ancestry, children, currentProductCategoryAncestry, setCurrentProductCategoryAncestry
}) => {
  const parseAncestry = (categoryAncestry) => {
    if (!categoryAncestry) return []

    const separator = '/'
    return map(split(categoryAncestry, separator), idStr => parseInt(idStr))
  }

  const openedAncestryIds = parseAncestry(currentProductCategoryAncestry)
  const isExpanded = includes(openedAncestryIds, id)
  const isSelected = id === openedAncestryIds[openedAncestryIds.length - 1]

  let targetAncestryIds = parseAncestry(ancestry)

  if (!isExpanded || (ancestry == null && !isSelected)) {
    targetAncestryIds.push(id)
  }

  return (
    <li className='category'>
      <div
        className={`category-link pointer ${isSelected ? 'selected' : ''}`}
        onClick={() => setCurrentProductCategoryAncestry(targetAncestryIds)}
      >
        <span className='category-name'>{name}</span>
        {children.length > 0 &&
          <i className={`bi ${isExpanded ? 'bi-chevron-up' : 'bi-chevron-down'}`} />
        }
      </div>
      <Collapse in={isExpanded}>
        <ul>
          {map(children, child =>
            <CategoryWithChildren
              {...child}
              key={child.id}
              currentProductCategoryAncestry={currentProductCategoryAncestry}
              setCurrentProductCategoryAncestry={setCurrentProductCategoryAncestry}
            />
          )}
        </ul>
      </Collapse>
    </li>
  )
}

const StockControl = ({ localization_context, current_location }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(
    localStorage.getItem('stocks_control_expanded_sidebar') !== 'false'
  );
  const [solidContainers, setSolidContainers] = useState({})
  const [liquidContainers, setLiquidContainers] = useState({})
  const [plateContainers, setPlateContainers] = useState({})
  const [feeders, setFeeders] = useState({})
  const [expandedContainerSlot, setExpandedContainerSlot] = useState(null)
  const [expandedPlateContainerSlot, setExpandedPlateContainerSlot] = useState(null)
  const [productCategories, setProductCategories] = useState([])
  const [solidProductCategories, setSolidProductCategories] = useState([])
  const [liquidProductCategories, setLiquidProductCategories] = useState([])
  const [currentProductCategory, setCurrentProductCategory] = useState({
    ancestry: null,
    id: null
  })
  const [flash, setFlash] = useState({
    flashOpen: false,
    flashType: null,
    flashMessage: null
  })

  const [replenishmentView, setReplenishmentView] = useState(!!new URLSearchParams(window.location.search).get('replenishment'))
  const [replenishmentSidebarOpen, setReplenishmentSidebarOpen] = useState(!!new URLSearchParams(window.location.search).get('open-sidebar'))
  const [showOrderPlacedModal, setShowOrderPlacedModal] = useState({show: false, orderState: null})
  const [replenishmentOrder, setReplenishmentOrder] = useState(emptyCart)
  const [replenishmentErrorMessages, setReplenishmentErrorMessages] = useState({})
  const [replenishmentHistorySidebarOpen, setReplenishmentHistorySidebarOpen] = useState(false)

  const [logs, setLogs] = useState<any[]>([])

  useEffect(() => {
    loadProductCategories()
    refreshStocks()
    fetchCurrentReplenishmentCart()

    consumer.subscriptions.create({
      channel: 'ReplenishmentStateChannel'
    }, {
      received: fetchCurrentReplenishmentCart
    })

  }, [])


  useEffect(() => {
    document.body.style.backgroundColor = replenishmentView ? "#F2F2F2" : "#FFFFFF"
  }, [replenishmentView])

  const setFlashMessage = (flashType, flashMessage) => (
    setFlash({flashType: flashType, flashMessage: flashMessage, flashOpen: true})
  )

  useEffect(() => {
    if(flash.flashOpen) {
      setTimeout(() => {
        setFlash({flashType: null, flashMessage: null, flashOpen: false})
      }, 3000)
    }
  }, [flash])

  const refreshStocks = async () => {
    await refreshSolidsStock()
    refreshLiquidsStock()
    refreshPlatesStock()
    refreshFeedersStock()
  }

  const loadProductCategories = () => {
    axios.get('/stocks/product_categories').then(({ data }) => {
      setProductCategories(data.product_categories)
      setSolidProductCategories(data.solid_product_categories)
      setLiquidProductCategories(data.liquid_product_categories)
    }).catch(() => setFlashMessage('danger', 'Failed to load product categories'))
  }

  const refreshSolidsStock = () => {
    return axios.get('/stocks/active_solid_containers').then(({ data }) => {
      setSolidContainers(data)
    }).catch(() => setFlashMessage('danger', 'Failed to load product containers'))
  }

  const refreshLiquidsStock = () => {
    axios.get('/stocks/active_liquid_containers').then(({ data }) => {
      setLiquidContainers(data)
    }).catch(() => setFlashMessage('danger', 'Failed to load sauce containers'))
  }

  const refreshPlatesStock = () => {
    axios.get('/stocks/active_plate_containers').then(({ data }) => {
      setPlateContainers(data)
    }).catch(() => setFlashMessage('danger', 'Failed to load plate containers'))
  }

  const refreshFeedersStock = () => {
    axios.get('/stocks/feeders.json').then(({ data }) => {
      setFeeders(data)
    }).catch(() => setFlashMessage('danger', 'Failed to load feeders'))
  }

  const fetchCurrentReplenishmentCart = () => {
    axios.get('/replenishment_orders/cart?format=json').then(({data}) => {
      if(data.id) {
        setReplenishmentOrder(data)
      }
    })
  }

  const plateReplenishmentsData = (plateReplenishments) => {
    return map(plateReplenishments, replenishment => (
      {
      id: replenishment.id,
      slot_number: replenishment.slotNumber,
      amount: replenishment.amount,
      _destroy: replenishment.destroy
    }))
  }

  const getCurrentProductCategories = () => {
    switch(currentTabIndex) {
      case 0:
        return solidProductCategories
      case 1:
        return liquidProductCategories
      default:
        return productCategories
    }
  }

  const productReplenishmentsData = (productReplenishments) => {
    return map(productReplenishments, replenishment => (
      {
        id: replenishment.id,
        slot_number: replenishment.slotNumber,
        product_id: replenishment.product?.id || null,
        product_container_id: replenishment.productContainer?.id,
        container_type: replenishment.containerType,
        _destroy: replenishment.destroy
      }
    ))
  }

  const updateCartRequest = (data, action) => {
    switch(action) {
      case ReplenishmentOrderAction.UPDATE_SENT_ORDER:
        return axios.put(`/replenishment_orders/${data.id}/merge_draft_to_sent_order?format=json`, data)
      case ReplenishmentOrderAction.PLACE_ORDER:
        return axios.put(`/replenishment_orders/${data.id}/place_order?format=json`, data)
      case ReplenishmentOrderAction.CANCEL:
        return axios.delete(`/replenishment_orders/${data.id}?format=json`)
      default:
        return data.id ? axios.put(`/replenishment_orders/${replenishmentOrder.id}?format=json`, data) // cart already stored, updating it
        : axios.post('/replenishment_orders?format=json', data) // first change of draft cart, creating it
    }
  }

  const updateReplenishmentCart = (updatedReplenishmentOrder: ReplenishmentOrder, action: ReplenishmentOrderAction) => {
    if(action == ReplenishmentOrderAction.CHANGE_DATE &&
      (!updatedReplenishmentOrder.deliveryDate?.isValid() || updatedReplenishmentOrder.deliveryDate == null)) {
      return
    }
    const data = {
      id: updatedReplenishmentOrder.id,
      delivery_date: updatedReplenishmentOrder.deliveryDate,
      plate_replenishments_attributes: plateReplenishmentsData(updatedReplenishmentOrder.plateReplenishments),
      product_replenishments_attributes: productReplenishmentsData(updatedReplenishmentOrder.productReplenishments)
    }

    updateCartRequest(data, action).then(({data}) => {
      handleOrderUpdated(data, action)
    }).catch(({ response }) => {
      setReplenishmentErrorMessages(response.data.full_errors)
    })
  }

  const handleOrderUpdated = (data, action) => {
    setReplenishmentErrorMessages({})
    if(action == ReplenishmentOrderAction.CANCEL) {
      if(replenishmentOrder.editableOrderId) {
        fetchCurrentReplenishmentCart()
      } else {
        setReplenishmentOrder(emptyCart)
      }
      return
    }
    setReplenishmentOrder(data)
    if(action == ReplenishmentOrderAction.PLACE_ORDER) {
      replenishmentOrderPlaced()
    } else if(action == ReplenishmentOrderAction.UPDATE_SENT_ORDER) {
      sentReplenishmentOrderUpdated()
    }
  }

  const switchTab = (index) => {
    setCurrentTabIndex(index)
  }

  const tabAlerts = () => {
    const hasAlert = (containers) => some(containers, container => container.show_alert)

    return {
      solids: hasAlert(solidContainers),
      liquids: hasAlert(liquidContainers),
      feeders: hasAlert(feeders),
      plates: hasAlert(plateContainers)
    }
  }

  const handleSearch = ({ target: { value } }) => {
    setSearchQuery(value)
    setCurrentProductCategory({ancestry: null, id: null})
  }

  useEffect(() => {
    localStorage.setItem('stocks_control_expanded_sidebar', isSidebarExpanded.toString())
    if(!isSidebarExpanded) {
      setCurrentProductCategory({ancestry: null, id: null})
    }
  }, [isSidebarExpanded])

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  }

  const expandContainer = (slotNumber, humanSlotNumber, isLiquid) => {
    setExpandedContainerSlot({number: slotNumber, humanNumber: humanSlotNumber, isLiquid})
  }

  const expandPlateContainer = (slotNumber) => {
    setExpandedPlateContainerSlot(slotNumber)
  }

  const closeContainer = () => {
    setExpandedContainerSlot(null)
  }

  const closePlateContainer = () => {
    setExpandedPlateContainerSlot(null)
  }

  const setCurrentProductCategoryAncestry = (currentProductCategoryAncestryIds) => {
    setCurrentProductCategory({
      ancestry: currentProductCategoryAncestryIds.join('/'),
      id: currentProductCategoryAncestryIds[currentProductCategoryAncestryIds.length - 1]
    })
  }

  const renderFlashNotification = () => flash.flashOpen && (
    <FlashNotification
      type={flash.flashType}
      message={flash.flashMessage}
      onClose={() => setFlash({...flash, flashOpen: false})}
    />
  )

  const toggleReplenishmentView = (replenishmentViewOn: boolean) => {
    setReplenishmentView(replenishmentViewOn)
  }

  const replenishmentOrderPlaced = () => {
    setReplenishmentSidebarOpen(false)
    setShowOrderPlacedModal({show: true, orderState: 'placed'})
  }

  const sentReplenishmentOrderUpdated = () => {
    setReplenishmentSidebarOpen(false)
    setShowOrderPlacedModal({show: true, orderState: 'updated'})
  }

  const getHumanOrderedSlots = (columns: number, slotCount: number) => {
    let humanOrderedSlots = [];
    for(let i = columns; i < slotCount + 1; i = i + columns) {
      for(let j = i; j > i - columns; j--) {
        humanOrderedSlots.push(j)
      }
    }

    return humanOrderedSlots;
  }

  const toggleReplenishmentSidebar = (toggle: boolean) => {
    setReplenishmentErrorMessages({})
    setReplenishmentSidebarOpen(toggle)
    if(!toggle) {
      setReplenishmentHistorySidebarOpen(false)
    }
  }

  const enterReplenishmentEditView = () => {
    axios.post('/replenishment_orders/enter_edit_order?format=json').then((response) => {
      setReplenishmentOrder(response.data)
    })
  }

  const getOrderState = () => {
    if(replenishmentOrder.state == 'draft') {
      return replenishmentOrder.editableOrderId != null ? 'editing' : 'draft'
    } else if(replenishmentOrder.state == 'closed') {
      return 'closed'
    } else {
      return 'sent'
    }
  }

  const orderState = getOrderState()

  const orderStatusClassName = () => {
    switch(orderState) {
      case 'draft': return 'green'
      case 'editing': return 'yellow'
      case 'sent': return 'green'
      case 'closed': return 'red'
    }
  }

  const currentDate = moment().startOf('day')

  const currentProductCategories = getCurrentProductCategories()

    return (
      <div className={`stock-control ${replenishmentView ? 'replenishment' : ''}`}>
        <LocalizationContext.Provider value={localization_context}>
          <div className='stock-control-header'>
            <div className='section-heading pl-3 pt-2'>
              <div className='section-heading-row bold'>{t(replenishmentView ? 'replenishment' : 'stock_control', { scope })}</div>
            </div>
            <Tabs currentTabIndex={currentTabIndex} switchTab={switchTab} alerts={tabAlerts()} />
            <Search query={searchQuery} onChange={handleSearch} />
            <div className='replenishment-actions'>
              {!replenishmentView &&
                <button className='btn btn-primary replenishment-btn' onClick={() => {toggleReplenishmentView(true)}}>{t('replenishment', { scope })}</button>
              }
              {replenishmentView &&
                <>
                  <div className='replenishment-icon-container'>
                    <Icon name='history' onClick={() => setReplenishmentHistorySidebarOpen(true)}/>
                    <div className={`status-indicator ${orderStatusClassName()}`}></div>
                  </div>
                  {(orderState == 'draft' || orderState == 'editing') &&
                  <div className='replenishment-icon-container'>
                    <Icon name='basket' onClick={() => setReplenishmentSidebarOpen(true)}/>
                    <div className={`status-indicator ${orderStatusClassName()}`}></div>
                  </div>
                  }
                  <Icon name='close' className="last" onClick={() => {toggleReplenishmentView(false)}}/>
                </>
              }
            </div>
          </div>
          <div className='stock-control-body'>
            <div className={`categories-sidebar ${!isSidebarExpanded ? 'collapsed' : ''}`}>
              <Collapse in={isSidebarExpanded} orientation='horizontal'>
                <ul className='categories-list'>
                  {map(currentProductCategories, productCategory =>
                    <CategoryWithChildren
                      { ...productCategory }
                      key={productCategory.id}
                      currentProductCategoryAncestry={currentProductCategory.ancestry}
                      setCurrentProductCategoryAncestry={setCurrentProductCategoryAncestry}
                    />
                  )}
                </ul>
              </Collapse>
              <div className='collapse-toggle' onClick={toggleSidebar}>
                <i className={`fa ${isSidebarExpanded ? 'fa-angle-left' : 'fa-angle-right'}`} />
              </div>
            </div>

            <div className='tab-content-wrapper'>
              <SwipeableViews index={currentTabIndex}>
                <SolidsView
                  searchQuery={searchQuery}
                  currentProductCategoryId={currentProductCategory.id}
                  currentDate={currentDate}
                  containers={solidContainers}
                  expandContainer={expandContainer}
                  replenishmentOrder={replenishmentOrder}
                  orderSlots={getHumanOrderedSlots}
                  replenishmentView={replenishmentView}
                />
                <LiquidsView
                  searchQuery={searchQuery}
                  currentProductCategoryId={currentProductCategory.id}
                  currentDate={currentDate}
                  containers={liquidContainers}
                  expandContainer={expandContainer}
                  replenishmentOrder={replenishmentOrder}
                  orderSlots={getHumanOrderedSlots}
                  replenishmentView={replenishmentView}
                />
                <FeedersView
                  feeders={feeders}
                />
                <PlatesView
                  plateContainers={plateContainers}
                  expandPlateContainer={expandPlateContainer}
                  replenishmentView={replenishmentView}
                  replenishmentOrder={replenishmentOrder}
                />
              </SwipeableViews>
            </div>
          </div>
          {(expandedContainerSlot && !replenishmentView) &&
            <ProductContainerModal
              expandedContainerSlot={expandedContainerSlot}
              expandedContainer={
                (!!expandedContainerSlot.isLiquid ? liquidContainers : solidContainers)[expandedContainerSlot.number]
              }
              closeContainer={closeContainer}
              refreshStock={expandedContainerSlot.isLiquid ? refreshLiquidsStock : refreshSolidsStock}
            />
          }
          {(expandedContainerSlot && replenishmentView) &&
            <ProductReplenishmentModal
              containerSlot={expandedContainerSlot}
              selectedContainer={
                (!!expandedContainerSlot.isLiquid ? liquidContainers : solidContainers)[expandedContainerSlot.number]
              }
              closeModal={closeContainer}
              replenishmentOrder={replenishmentOrder}
              updateCart={updateReplenishmentCart}
              toggleSidebar={toggleReplenishmentSidebar}
            />
          }
          {(expandedPlateContainerSlot && !replenishmentView) &&
            <PlateContainerModal
              expandedContainerSlot={expandedPlateContainerSlot}
              expandedContainer={plateContainers[expandedPlateContainerSlot]}
              closeContainer={closePlateContainer}
              refreshStock={refreshPlatesStock}
            />
          }
          {(expandedPlateContainerSlot && replenishmentView) &&
            <BowlReplenishmentModal
              containerSlot={expandedPlateContainerSlot}
              container={plateContainers[expandedPlateContainerSlot]}
              closeModal={closePlateContainer}
              updateCart={updateReplenishmentCart}
              replenishmentOrder={replenishmentOrder}
              toggleSidebar={toggleReplenishmentSidebar}
            />
          }
          {renderFlashNotification()}
          <ReplenishmentCartSidebar
            replenishmentOrder={replenishmentOrder}
            sidebarOpen={replenishmentSidebarOpen}
            toggleSidebar={toggleReplenishmentSidebar}
            updateCart={updateReplenishmentCart}
            errorMessages={replenishmentErrorMessages}
            orderState={orderState}
          />
          <ReplenishmentHistorySidebar
            enterReplenishmentEditView={enterReplenishmentEditView}
            replenishmentOrder={replenishmentOrder}
            sidebarOpen={replenishmentHistorySidebarOpen}
            closeSidebar={() => setReplenishmentHistorySidebarOpen(false)}
            openCartSidebar={toggleReplenishmentSidebar}
          />
          {showOrderPlacedModal.show &&
          <ReplenishmentOrderPlacedModal
            showOrderPlacedModal={showOrderPlacedModal}
            replenishmentOrder={replenishmentOrder}
            handleClose={() => {setShowOrderPlacedModal({show: false, orderState: ''})}}
            location={current_location}
          />
          }
        </LocalizationContext.Provider>
      </div>
    )
  }

export default StockControl
